import React, { lazy, Suspense } from 'react';
import { Outlet, createBrowserRouter } from 'react-router-dom';
import AppLayout from 'layouts/AppLayout';
import Login from 'pages/Auth/Login';
import ForgotPassword from 'pages/Auth/ForgotPassword';
import ResetPassword from 'pages/Auth/ResetPassword';
import CreateAccount from 'pages/Auth/CreateAccount';
import RequireAuth from 'HOC/RequireAuth';
import Spinner from 'components/Spinner';
import TermsAndConditions from 'pages/Auth/TermsAndCondition';
import PageNotFound from 'pages/404';
// Lazy Loaded Pages
const Patients = lazy(() => import("pages/Clinicals/Patients/AllPatients"))
const ViewServiceCategory = lazy(() => import("pages/ServiceMgt./Services/ViewServiceCategory"))
const InPatients = lazy(() => import("pages/Clinicals/Patients/InPatients"))
const DischargedPatients = lazy(() => import("pages/Clinicals/Patients/DischargedPatients"))
const AdmitPatient = lazy(() => import("pages/Clinicals/Patients/InPatients/AdmitPatient"))
const PatientDetails = lazy(() => import("pages/Clinicals/Patients/AllPatients/PatientDetails"))
const VisitDetails = lazy(() => import('pages/Clinicals/Patients/AllPatients/PatientDetails/Components/VisitDetails/'));
const ProgressNoteNewRecord = lazy(() => import('pages/Clinicals/Patients/InPatients/SingleInPatient/ProgressNote/NewFlow/NewRecord'));
const NewRecord = lazy(() => import('pages/Clinicals/Patients/AllPatients/PatientDetails/Components/VisitDetails/NewRecord'));
const SingleInPatient = lazy(() => import("pages/Clinicals/Patients/InPatients/SingleInPatient"))
const ProgressNotes = lazy(() => import("pages/Clinicals/Patients/InPatients/SingleInPatient/ProgressNote"))
const InPatientsHighRisk = lazy(() => import("pages/Clinicals/Patients/InPatients/HighRisk"))
const VitalSigns = lazy(() => import("pages/Clinicals/VitalSigns/index"))
const NewVitals = lazy(() => import("pages/Clinicals/VitalSigns/NewVitals"))
const VitalSignsDetails = lazy(() => import("pages/Clinicals/VitalSigns/VitalSignsDetails"))
const LabTest = lazy(() => import("pages/Clinicals/LabTest"))
const LabTestDetails = lazy(() => import("pages/Clinicals/LabTest/LabTestDetails"))
const Onboarding = lazy(() => import("pages/Onboarding/Index.js"))
const Visit = lazy(() => import("pages/Clinicals/Vists/Visit"))
const ViewSingleVist = lazy(() => import("pages/Clinicals/Vists/ViewSingleVist"));
const Locations = lazy(() => import("pages/Settings/Locations"))
const ActivityLog = lazy(() => import("pages/Settings/ActivityLog"))
const Specialty = lazy(() => import("pages/Settings/Specialty"))
const ViewSpecialty = lazy(() => import("pages/Settings/Specialty/ViewSingleSpecialty"))
const BillAndPayments = lazy(() => import("pages/ServiceMgt./BillAndPayments"))
const BillDetails = lazy(() => import("pages/ServiceMgt./BillAndPayments/BillDetails"))
const Wallet = lazy(() => import("pages/ServiceMgt./BillAndPayments/Wallet"))
const Policies = lazy(() => import("pages/Settings/Policies"))
const CreatePolicy = lazy(() => import("pages/Settings/Policies/CreatePolicy"))
const ViewPolicy = lazy(() => import("pages/Settings/Policies/ViewPolicy"))
const RolesAndPermission = lazy(() => import("pages/Settings/RolesAndPermission"))
const StaffManagement = lazy(() => import("pages/Settings/StaffManagement"))
const Services = lazy(() => import("pages/ServiceMgt./Services"))
const Category = lazy(() => import("pages/ServiceMgt./Services/Category"))
const Profile = lazy(() => import("pages/Settings/Profile"))
const Appointments = lazy(() => import("pages/Appointments"))
const NewAppointment = lazy(() => import("pages/Appointments/NewAppointment"))
const Scheduling = lazy(() => import("pages/EShift/Scheduling"));
const SingleShift = lazy(() => import("pages/EShift/Scheduling/Shift/SingleShift"));
const NewShift = lazy(() => import("pages/EShift/Scheduling/Shift/NewShift"));
const StaffShift = lazy(() => import("pages/EShift/Scheduling/Shift/SingleShift/StaffCalendar"));
const CalendarForStaff = lazy(() => import('pages/EShift/Scheduling/Shift/CalendarForStaff'))
const CreateBill = lazy(() => import('pages/ServiceMgt./BillAndPayments/CreateBill/CreateBill'))
const NewVisit = lazy(() => import('pages/Clinicals/Vists/NewVisit'))
const ProgressNoteDetails = lazy(() => import('pages/Clinicals/Patients/InPatients/SingleInPatient/ProgressNote/ProgressNoteDetails'))
const LiveSession = lazy(() => import('pages/Appointments/LiveSession'))
const Prescriptions = lazy(() => import('pages/Pharmacy/Prescriptions/Prescriptions'));
const ViewPrescription = lazy(() => import('pages/Pharmacy/Prescriptions/Prescriptions/ViewPrescription'));
const PrescriptionCheckout = lazy(() => import('pages/Pharmacy/Prescriptions/Prescriptions/PrescriptionCheckout'));
const NewPrescription = lazy(() => import('pages/Pharmacy/Prescriptions/NewPrescription'));
const History = lazy(() => import('pages/Pharmacy/Prescriptions/History'));
const ViewPatientPrescription = lazy(() => import('pages/Pharmacy/Prescriptions/History/ViewPatientPrescription'));
const ProductList = lazy(() => import('pages/Pharmacy/Inventory/ProductList'));
const StockControl = lazy(() => import('pages/Pharmacy/Inventory/StockControl'));
const ViewStockHistory = lazy(() => import('pages/Pharmacy/Inventory/ProductList/ViewStockHistory'));
const ViewDispenseHistory = lazy(() => import('pages/Pharmacy/Inventory/ProductList/ViewDispenseHistory'));
const Categories = lazy(() => import('pages/Pharmacy/Inventory/Categories'));
const ViewCategory = lazy(() => import('pages/Pharmacy/Inventory/Categories/ViewCategory'));

const router = createBrowserRouter([
  {
    path: '',
    element: <Login />,
  },
  {
    path: 'create-account',
    element: <CreateAccount />,
  },
  {
    path: 'verify-account',
    element: <CreateAccount />,
  },
  {
    path: 'forgot-password',
    element: <ForgotPassword />,
  },
  {
    path: 'create-password',
    element: <ResetPassword />,
  },
  {
    path: 'terms-and-condition',
    element: <TermsAndConditions />,
  },
  {
    path: 'onboarding',
    element: (
      <Suspense fallback={<Spinner />}>
        <RequireAuth>
          <Onboarding />
        </RequireAuth>
      </Suspense>
    ),
  },
  {
    path: '/',
    errorElement: <div>Something went wrong</div>,
    element: (
      <AppLayout>
        <Outlet />
      </AppLayout>
    ),
    children: [
      {
        path: 'dashboard',
        element: <div>dashboard</div>,
      },
      {
        path: 'appointments',
        element: <Appointments />,
      },
      {
        path: 'appointments/new-appointment',
        element: <NewAppointment />,
      },
      {
        path: 'clinicals/visits',
        element: <Visit />,
      },
      {
        path: 'clinicals/visits/:id',
        element: <ViewSingleVist />,
      },
      {
        path: 'clinicals/visits/new',
        element: <NewVisit />,
      },
      {
        path: 'clinicals/vital-signs',
        element: <VitalSigns />,
      },
      {
        path: 'clinicals/vital-signs/new',
        element: <NewVitals />,
      },
      {
        path: 'clinicals/vital-signs/:id/edit',
        element: <NewVitals />,
      },
      {
        path: 'clinicals/vital-signs/:id',
        element: <VitalSignsDetails />,
      },
      {
        path: 'clinicals/patients',
        element: <Patients />,
      },
      {
        path: 'clinicals/in-patients',
        element: <InPatients />,
      },
      {
        path: 'clinicals/in-patients/admit-patient',
        element: <AdmitPatient />,
      },
      {
        path: 'clinicals/in-patients/:id',
        element: <SingleInPatient />,
      },
      {
        path: 'clinicals/in-patients/:id/progress-notes/:id/:purpose',
        element: <ProgressNoteDetails />,
      },
      {
        path: 'clinicals/in-patients/:id/progress-notes',
        element: <ProgressNotes />,
      },
      {
        path: 'clinicals/in-patients/:id/progress-notes/:visitId/new-record/:progressNoteType',
        element: <ProgressNoteNewRecord />,
      },
      {
        path: 'clinicals/patients/:id',
        element: <PatientDetails />,
      },
      {
        path: 'clinicals/patients/:id/visit/:visitId',
        element: <VisitDetails />,
      },
      {
        path: 'clinicals/patients/:id/visit/:visitId/new-record',
        element: <NewRecord />,
      },
      {
        path: 'clinicals/in-patients/high-risk',
        element: <InPatientsHighRisk />,
      },
      {
        path: 'clinicals/discharged-patients',
        element: <DischargedPatients />,
      },
      {
        path: 'clinicals/lab-test',
        element: <LabTest />,
      },
      {
        path: 'clinicals/lab-test/:id',
        element: <LabTestDetails />,
      },
      {
        path: 'e-shift/scheduling',
        element: <Scheduling />,
      },
      {
        path: 'e-shift/scheduling/calendar/:shiftId/:providerId',
        element: <CalendarForStaff />,
      },
      {
        path: 'e-shift/scheduling/new-shift',
        element: <NewShift />,
      },
      {
        path: 'e-shift/scheduling/:id',
        element: <SingleShift />,
      },
      {
        path: 'e-shift/scheduling/:id/:id',
        element: <StaffShift />,
      },
      {
        path: 'settings/profile',
        element: <Profile />,
      },
      {
        path: 'settings/locations',
        element: <Locations />,
      },
      {
        path: 'settings/activity-log',
        element: <ActivityLog />,
      },
      {
        path: 'settings/specialty',
        element: <Specialty />,
      },
      {
        path: 'settings/specialty/:id',
        element: <ViewSpecialty />,
      },
      {
        path: 'settings/policies',
        element: <Policies />,
      },
      {
        path: 'settings/policies/:id/:type',
        element: <ViewPolicy />,
      },
      {
        path: 'settings/policies/create-policy',
        element: <CreatePolicy />,
      },
      {
        path: 'settings/roles-and-permissions',
        element: <RolesAndPermission />,
      },
      {
        path: 'settings/staff-management',
        element: <StaffManagement />,
      },
      {
        path: 'service/list',
        element: <Services />,
      },
      {
        path: 'service/list/:id',
        element: <ViewServiceCategory />,
      },
      {
        path: 'service/category',
        element: <Category />,
      },
      {
        path: 'service/bill-and-payment',
        element: <BillAndPayments />,
      },
      {
        path: 'service/bill-and-payment/create-bill',
        element: <CreateBill />,
      },
      {
        path: 'service/bill-and-payment/:id',
        element: <BillDetails />,
      },
      {
        path: 'service/bill-and-payment/wallet/',
        element: <Wallet />,
      },
      {
        path: 'pharmacy/prescription',
        element: <Prescriptions />,
      },
      {
        path: 'pharmacy/prescription/history',
        element: <History />,
      },
      {
        path: 'pharmacy/prescription/history/:id',
        element: <ViewPatientPrescription />,
      },
      {
        path: 'pharmacy/prescription/new',
        element: <NewPrescription />,
      },
      {
        path: 'pharmacy/prescription/:id/edit',
        element: <NewPrescription />,
      },
      {
        path: 'pharmacy/prescription/:id/add-medication',
        element: <NewPrescription />,
      },
      {
        path: 'pharmacy/prescription/:id',
        element: <ViewPrescription />,
      },
      {
        path: 'pharmacy/prescription/:id/history',
        element: <ViewPrescription />,
      },
      {
        path: 'pharmacy/prescription/:id/checkout',
        element: <PrescriptionCheckout />,
      },
      {
        path: 'pharmacy/inventory',
        element: <ProductList />,
      },
      {
        path: 'pharmacy/inventory/stock-control',
        element: <StockControl />,
      },
      {
        path: 'pharmacy/inventory/:id/stock-history',
        element: <ViewStockHistory />,
      },
      {
        path: 'pharmacy/inventory/:id/dispense-history',
        element: <ViewDispenseHistory />,
      },
      {
        path: 'pharmacy/inventory/categories',
        element: <Categories />,
      },
      {
        path: 'pharmacy/inventory/categories/:id',
        element: <ViewCategory />,
      },
    ],
  },
  {
    path: 'appointments/live/:id',
    element: (
      <Suspense fallback={<Spinner />}>
        <LiveSession />
      </Suspense>
    ),
  },
  {
    path: '*',
    element: <PageNotFound />,
  },
]);

export { router };
