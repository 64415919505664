import React from 'react';
import PropTypes from 'prop-types';

const RadioButtonSelect = ({
  label,
  labelStyles,
  variant,
  onChange,
  isChecked,
  markerVariant,
  showLabel = true,
  showImage = false,
  imageSrc,
  imageAlt = '',
  disabled,
}) => {
  return (
    <div className={`${variant || 'border border-neutral_stroke rounded-lg px-4 py-3 w-full'}`}>
      <label
        className={`${labelStyles || 'text-neutral_body'} container font-campton_r ${
          disabled ? 'cursor-not-allowed' : 'cursor-pointer'
        }`}
      >
        {showLabel ? label : ''}

        {showImage && <img src={imageSrc} alt={imageAlt} className="w-full h-auto" />}

        <input type="checkbox" data-testid={label} onChange={onChange} checked={isChecked} disabled={disabled} />
        <span className={`${isChecked ? 'checked-active' : 'checked-inactive'} ${markerVariant} checkmark `}></span>
      </label>
    </div>
  );
};

export default RadioButtonSelect;
RadioButtonSelect.propTypes = {
  label: PropTypes.any,
  showLabel: PropTypes.bool,
  labelStyles: PropTypes.string,
  variant: PropTypes.string,
  disabled: PropTypes.bool,
  markerVariant: PropTypes.string,
  isChecked: PropTypes.bool,
  onChange: PropTypes.func,
  showImage: PropTypes.bool,
  imageSrc: PropTypes.string,
  imageAlt: PropTypes.string,
};
