import { medications, patient, progressNote } from 'services/endpoints'
import { institution } from 'services/endpoints/institution'
import { createCustomAsyncThunk, customAsyncThunk } from 'utils/createThunk'

const getVitals = createCustomAsyncThunk('clinicals/getVitals', 'get', institution.institution)
const getVital = createCustomAsyncThunk('clinicals/getVital', 'get', institution.vitals)
const createVitals = createCustomAsyncThunk('clinicals/createVitals', 'post', institution.create_vitals)
const getLabTests = createCustomAsyncThunk('clinicals/getLabTests', 'get', institution.institution)
const getLabTest = createCustomAsyncThunk('clinicals/getLabTest', 'get', institution.lab_test)
const updateLabTest = createCustomAsyncThunk('clinicals/updateLabTest', 'patch', institution.visits)
const getVisits = createCustomAsyncThunk('clinicals/getVisits', 'get', institution.institution)
const getSingleVisit = createCustomAsyncThunk('clinicals/getSingleVisit', 'get', institution.visits)
const addEncounterNotes = createCustomAsyncThunk('clinicals/addEncounterNotes', 'post', institution.encounter_note);
const getEncounterNotes = createCustomAsyncThunk('clinicals/getEncounterNotes', 'get', institution.encounter_note);
const getSingleEncounterNote = createCustomAsyncThunk('clinicals/getSingleEncounterNote', 'get', institution.encounter_note_details);
const getICD = createCustomAsyncThunk('clinicals/getICD', 'get', institution.icd);
const updateVisit = createCustomAsyncThunk('clinicals/updateVisit', 'patch', institution.visits)
const createChiefComplaint = createCustomAsyncThunk('clinicals/createChiefComplaint', 'post', institution.chief_complaint)
const createVisitSummary = createCustomAsyncThunk('clinicals/createVisitSummary', 'post', institution.visit_summary)
const updateVisitSummary = createCustomAsyncThunk('clinicals/updateVisitSummary', 'patch', institution.visit_summary)
const createScanFindings = createCustomAsyncThunk('clinicals/createScanFindings', 'post', institution.scan_findings);
const updateScanFindings = createCustomAsyncThunk('clinicals/updateScanFindings', 'patch', institution.scan_findings);
const addMedicalRecord = createCustomAsyncThunk('clinicals/updateMedicalRecord', 'post', institution.update_medical_record)
const getMedications = createCustomAsyncThunk('clinicals/getMedications', 'get', medications.medications)
const createMedications = customAsyncThunk('clinicals/createMedications', 'post', medications.medications)
const updateMedicationStatus = customAsyncThunk('clinicals/createMedications', 'patch', medications.update_medication_status)
const getProcedure = createCustomAsyncThunk('clinicals/getProcedure', 'get', patient.procedure)
const createProcedure = customAsyncThunk('clinicals/createProcedure', 'post', patient.procedure)
const referPatient = customAsyncThunk('clinicals/referPatient', 'post', patient.referral)
const getReferrals = createCustomAsyncThunk('clinicals/getReferrals', 'get', patient.referral)
const createTheraphy = customAsyncThunk('clinicals/createTheraphy', 'post', patient.therapy)
const getTherapies = createCustomAsyncThunk('clinicals/getTherapies', 'get', patient.therapy)
const getVisitAnalytics = createCustomAsyncThunk('patient/getVisitAnalytics', 'get', institution.get_visit_chart)
const getPatientAppointment = createCustomAsyncThunk('patient/getPatientAppointment', 'get' , patient.patient_profile )
const getProgressNotes = createCustomAsyncThunk('clinicals/getProgressNotes', 'get', progressNote.progress_notes)
const addProgressNote = customAsyncThunk('clinicals/addProgressNote', 'post', progressNote.progress_notes)
const createOtherNotes = customAsyncThunk('clinicals/createOtherNotes', 'post', patient.others_note);
const createOperativeNotes = customAsyncThunk('clinicals/createOperativeNotes', 'post', patient.operative_note);
const createOperativePlan = customAsyncThunk('clinicals/createOperativePlan', 'post', patient.operative_plan);
const getPostOperativeNotes = createCustomAsyncThunk('clinicals/getPostOperativeNotes', 'get', patient.operative_note);
const getOperativePlan = createCustomAsyncThunk('clinicals/getOperativePlan', 'get', patient.operative_plan);
const getOthersNotes = createCustomAsyncThunk('clinicals/getOthersNotes', 'get', patient.others_note);

export {
  getVitals,
  getVital,
  addEncounterNotes,
  getEncounterNotes,
  getICD,
  getSingleEncounterNote,
  getPatientAppointment,
  createVitals,
  getLabTests,
  getLabTest,
  createMedications,
  getMedications,
  getProgressNotes,
  updateVisit,
  addMedicalRecord,
  getVisits,
  getSingleVisit,
  updateMedicationStatus,
  updateLabTest,
  getVisitAnalytics,
  getProcedure,
  createProcedure,
  getReferrals,
  referPatient,
  getTherapies,
  createTheraphy,
  addProgressNote,
  createVisitSummary,
  updateVisitSummary,
  createScanFindings,
  updateScanFindings,
  createChiefComplaint,
  createOtherNotes,
  createOperativeNotes,
  createOperativePlan,
  getPostOperativeNotes,
  getOperativePlan,
  getOthersNotes,
};